<div class="container-fluid" id="conditions">
  <app-header-steps></app-header-steps>
  <div class="row">
    <div class="col-12 px-0 px-md-4">
      <div class="spinner-border" role="status" *ngIf="isLoading">
        <span class="sr-only">Cargando...</span>
      </div>
      <div *ngIf="!isLoading">
        <div class="container-fluid content px-0 pt-0 " *ngIf="showChile" >
          <div class="p-0 m-0" style="background-color: rgb(253, 206, 201); border-radius: 20px 20px 0 0; height: auto;">
            <div class="col-12 align-content-center text-center pt-3 px-4 pb-3" >
              <img src="../../../../assets/img/res-no.svg" alt="logo-proyecto" style="min-height: 5rem;">
              <h2 class="txt-title py-2">Transacción Fallida</h2>
              <p class="txt-size-16">{{maxDate | date_format: quotationData.lenguaje}}</p>
            </div>
          </div>
          <div class="col-md-12 w-100 p-4 my-2">
            <div class="col-md-3 pb-3 d-flex" style="float: right;" scope="col"><img class="w-75" src="../../../../assets/img/logowebpay.png" alt="logo Transbank"></div>

            <div class="col-md-12 p-0">
              <p class="ml-4 mt-2 mb-2"><strong>{{quotationData.cliente.natNombre}} {{quotationData.cliente.natApellidoPaterno}} es posible que tu transacción no se haya realizado por:</strong>
              </p>
              <ul id="conditions-list" class="list-group text-size-14 ml-0">

                <li class="list-group-item borderless pb-0">
                  <span class="material-icons size-md">done</span>
                  Tu tarjeta de crédito o débito no cuenta con saldo suficiente.
                </li>
                <li class="list-group-item borderless pb-0">
                  <span class="material-icons size-md">done</span>
                  Error en el ingreso de los datos de tu tarjeta de crédito o débito.
                </li>
                <li class="list-group-item borderless pb-0">
                  <span class="material-icons size-md">done</span>
                  Tarjeta aún no habilitada en el sistema financiero
                </li>
                <li class="list-group-item borderless pb-0">
                  <span class="material-icons size-md">done</span>
                  El usuario no realizó ninguna operación.
                </li>
              </ul>
            </div>
            <div class="row justify-content-center align-items-baseline pt-4 pt-sm-0 pb-4 my-4">
              <div class="col-md-6">
                <a
                  type="button"
                  alt="link sitio proyecto"
                  href="{{quotationData.proyecto.urlProyecto}}"
                  class="btn btn-outline-dark btn-lg mt-4 mr-2 btn-cancel w-100" style="padding: 1rem !important;">  
                  Cancelar
                </a>
              </div>
              <div class="col-md-6">
                <button class="btn btn-dark btn-lg mt-4 mr-2 btn-submit w-100" type="button" (click)="getWebpayToken()" [disabled]="disabledPayButton === true">
                  Ir a pagar
                  <div class="spinner-border spinner-button" role="status" *ngIf="webpayLoading">
                    <span class="sr-only">Cargando...</span>
                  </div>
                </button>
                <form  ngNoForm method="post" [action]="webpayUrl" id="formWebpay">
                  <input id="token_ws" type="hidden" name="token_ws" [value]="webpayToken" >
                  <input type="submit" hidden id="submitWebpayButton">
                </form>
              </div>
            </div>
          </div>
        </div>
          <div class="container-fluid content px-0 pt-0 " *ngIf="showPeru">
            <div class="p-0 m-0" style="background-color: rgb(253, 206, 201); border-radius: 20px 20px 0 0; height: auto;">
              <div class="col-12 align-content-center text-center pt-3 px-4 pb-3" >
                <img src="../../../../assets/img/res-no.svg" alt="logo-proyecto" style="min-height: 5rem;">
                <h2 class="txt-title py-2">Transacción Denegada</h2>
                <p class="txt-size-16">{{maxDate | date:'dd/MM/yyyy HH:mm:ss'}}</p> 
              </div>
            </div>
            <div class="col-md-12 w-100 p-4 my-2">
              <div class="col-md-2 pb-3 d-flex" style="float: right;" scope="col"><img class="w-100" src="../../../../assets/img/logo_niubiz.PNG" alt="logo Transbank"></div>
  
              <div class="col-md-12 p-0">
                <p class="ml-4 mt-2 mb-2"><strong>{{quotationData.cliente.natNombre}} {{quotationData.cliente.natApellidoPaterno}} es posible que tu transacción no se haya realizado por:</strong>
                </p>
                <ul id="conditions-list-peru" class="list-group ml-0">
  
                  <li class="list-group-item borderless pb-0 text-size-14">
                    <span class="material-icons size-md">done</span>
                    Operación no permitida - Contactar con entidad emisora de su tarjeta.
                  </li>
                  <li class="list-group-item borderless pb-0 text-size-14">
                    <span class="material-icons size-md">done</span>
                    Clave secreta incorrecta.
                  </li>
                  <li class="list-group-item borderless pb-0 text-size-14">
                    <span class="material-icons size-md">done</span>
                    Código de seguridad inválido - Contactar con entidad emisora de su tarjeta.
                  </li>
                  <li class="list-group-item borderless pb-0 text-size-14">
                    <span class="material-icons size-md">done</span>
                    Tarjeta con restricciones de crédito - Contactar con entidad emisora de su tarjeta.
                  </li>
  
                </ul>
              </div>
              <div class="row justify-content-center align-items-baseline pt-4 pt-sm-0 pb-4 my-4">
                <div class="col-md-6">
                  <a
                    type="button"
                    alt="link sitio proyecto"
                    href="{{quotationData.proyecto.urlProyecto}}"
                    class="btn btn-outline-dark btn-lg mt-4 mr-2 btn-cancel w-100" style="padding: 1rem !important;">  
                    Cancelar
                  </a>
                </div>
                <div class="col-md-6" id="pagoVisanet" >
                  <div id="botonPagoVisanet"></div>
                  <button id="btn-volver-intentar-pago" class="btn btn-dark btn-lg mt-4 mr-2 btn-submit w-100" type="button" (click)="formularioPagoVisanet()" [disabled]="disabledPayButton === true">
                    Volver a intentar
                    <div id="spinner-btn-volver-intentar-pago" class="spinner-border spinner-button" role="status" *ngIf="webpayLoading">
                      <span class="sr-only">Cargando...</span>
                    </div>
                  </button>
                  <form  ngNoForm method="post" [action]="webpayUrl" id="formWebpay">
                    <input id="token_ws" type="hidden" name="token_ws" [value]="webpayToken" >
                    <input type="submit" hidden id="submitWebpayButton">
                  </form>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>



