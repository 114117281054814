import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VisanetModel } from '../../models/visanet.model';

@Injectable({
  providedIn: 'root'
})
export class VisanetService {

  constructor(protected http: HttpClient) { }

  getVisanet(apikey: string, hash: string): Observable<VisanetModel> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
    return this.http.post<VisanetModel>(environment.urlApi + '/reserva/visanet/iniciar-transaccion/'+ hash, '' ,{headers});
    
  }

}
