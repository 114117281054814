import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  url: string;
  apyKey: string;

  constructor(protected http: HttpClient) { 
    this.apyKey = '3913d619991d4fc5a88d128c4f8599da';
    this.url = environment.urlApi + '/ciudades';
  }
  getCitys(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', this.apyKey);
    return this.http.get(this.url,{headers})
    .pipe(
      map ( (resp: any) => {
        console.log(resp)
        return true;
      })
    );
  }
}
