import { Component, OnInit } from '@angular/core';
import { translate } from "@rxweb/translate";

@Component({
  selector: 'app-header-steps',
  templateUrl: './header-steps.component.html',
  styleUrls: ['./header-steps.component.css']
})
export class HeaderStepsComponent implements OnInit {

  @translate() multilingualContent:{[key:string]:any};

  constructor( ) { }

  ngOnInit() { }

}
