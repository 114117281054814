<div class="container background-imagen body align-items-center p-0 m-0" >
  <!-- start - contenedor del widget -->
<div class="container content">
  <div class="row">
    <div class="col-xs-12 col-sm-12">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- start - footer -->
  <app-footer></app-footer>
  <!-- end - footer -->
</div>
 <!-- end - contenedor del widget -->
</div>