import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'; 
import { DistritoModel } from '../../models/distrito.model';


@Injectable({
  providedIn: 'root'
})
export class DistritosService {
  url: string;
  constructor(protected http: HttpClient) { 
    this.url = environment.urlApi + '/distritos';
    console.log(environment.urlApi);
  }
  getDistritos(apikey: string, hash: string): Observable<DistritoModel[]> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
      console.log(this.url);
      console.log(headers);
    return this.http.get<DistritoModel[]>(this.url,{headers});
  }
}
