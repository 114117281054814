import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WebPayModel } from '../../models/webpay.model';

@Injectable({
  providedIn: 'root'
})
export class WebpayService {

  constructor(protected http: HttpClient) { }

  getWebpay(apikey: string, hash: string): Observable<WebPayModel> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
    return this.http.post<WebPayModel>(environment.urlApi + '/reserva/webpay/mall/deuda/'+ hash, '' ,{headers});
    
  }
}
