import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './components/pages/welcome/welcome.component';
import { ReservationComponent } from './components/pages/reservation/reservation.component';
import { ReceiptComponent } from './components/pages/receipt/receipt.component';
import { RetryPaymentComponent } from './components/pages/retry-payment/retry-payment.component';
import { WebpayComponent } from './components/pages/webpay/webpay.component';

const routes: Routes = [
  {
    path: 'welcome/:apikey/:hash', 
    component: WelcomeComponent
  },
  {
    path: 'reservation/:apikey/:hash',
    component: ReservationComponent
  },
  {
    path: 'webpay/:apikey/:hash',
    component: WebpayComponent
  },
  {
    path: 'reservation/receipt/:apikey/:hash',
    component: ReceiptComponent
  },
  {
    path: 'reservation/retry/:apikey/:hash',
    component: RetryPaymentComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
