import { Component, OnInit } from '@angular/core';
import { QuotationDataService } from '../../services/quotation-data.service';
import { QuotationDataModel } from '../../models/quotation-data.model';
import { Router, ActivatedRoute } from "@angular/router";
import { PreviousReservationDataService } from '../../services/previousReservationData/previous-reservation-data.service';
import { PreviousReservationModel } from '../../models/previousReservation.model';
import { WebpayService} from '../../services/webpay/webpay.service';
import { environment } from 'src/environments/environment';
import { VisanetService } from '../../services/visanet/visanet.service';
import { RxTranslation, translate } from "@rxweb/translate";

@Component({
  selector: 'app-retry-payment',
  templateUrl: './retry-payment.component.html',
  styleUrls: ['./retry-payment.component.css']
})
export class RetryPaymentComponent implements OnInit {

  @translate() multilingualContent:{[key:string]:any};
  isLoading: boolean;
  quotationData: QuotationDataModel;
  previousReservation: PreviousReservationModel;
  clientFirstName: string;
  clientFatherName: string;
  clientMotherName: string;
  clientMail: string;
  clientMobileNumber: string;
  clientAddress: string;
  clientAddressNumber: string;
  clientComplement: string;
  clientNeighborhood: string;
  clientCity: string;
  maxDate =  new Date();
  webpayUrl: string;
  webpayData: any;
  webpayToken: string;
  disabledPayButton = false;
  webpayLoading = false;
  today: any;
  apiKey : string;
  hashCotizacion: string;
  expDays: number;
  dateExp: any;
  leng: string;
  showChile: boolean = false;
  showPeru: boolean = false;

  constructor( 
    private router: Router,
    private quotationDataService: QuotationDataService,
    private _route: ActivatedRoute,
    private reservationService: PreviousReservationDataService,
    private webPayService: WebpayService,
    private visanetService: VisanetService,
    private traslation: RxTranslation,
  ){
    this.apiKey = this._route.snapshot.paramMap.get('apikey');
    this.hashCotizacion = this._route.snapshot.paramMap.get('hash');
  }

  ngOnInit() {
    this.isLoading = true;
    this.changeCountry();
    this.reservationService.getReservations(this.apiKey, this.hashCotizacion)
      .subscribe(
        resp => {
          this.previousReservation = resp;
          this.expDays= this.previousReservation.duracionOferta;
        },
        error => {
          console.log('Error al Cargar los Datos Previos a la Reserva', error);
        }
      );

    this.quotationDataService.getQuotationData(this.apiKey, this.hashCotizacion).subscribe(
      (response) => {

        this.quotationData = response;
        this.isLoading = false;
        this.clientFirstName = this.quotationData.cliente.natNombre;
        this.clientFatherName = this.quotationData.cliente.natApellidoPaterno;
        this.clientMotherName = this.quotationData.cliente.natApellidoMaterno;
        this.traslation.change(this.quotationData.lenguaje);
        
      },
      (error) => {
        console.log("error", error);
      }
    );
  }


  changeCountry(){
    this.leng = environment.leng;
    switch(this.leng){
      case 'es-CL': this.showChile = true;
      break;
      case 'es-PE': this.showPeru = true;
      break;
    }
  }

  getWebpayToken() {
    this.webpayLoading = true;
    this.disabledPayButton = true;
    this.webPayService.getWebpay(this.apiKey, this.hashCotizacion).subscribe(
      resp => {
        this.webpayData = resp;
        this.webpayToken = this.webpayData.token;
        this.webpayUrl = this.webpayData.url;
        setTimeout(() => {
          this.sendToWebpay();
        }, 800);

      },
      error => {
        console.log('Error al cargar servicio de webpay', error);
        this.webpayLoading = false;
        this.disabledPayButton = true;
      }
    );
  }

  sendToWebpay(): void {
    const button: HTMLElement = document.getElementById('submitWebpayButton') as HTMLElement;
    button.click();
  }

  iniciarFormularioVisanet() {
    this.webpayLoading = true;
    this.disabledPayButton = true;
    this.visanetService.getVisanet(this.apiKey, this.hashCotizacion).subscribe(
      resp => {
        
        const visanet       = document.getElementById('botonPagoVisanet');
        const formVisanet   = document.createElement('form');
        const scriptDynamic = document.createElement('script');

        formVisanet.action  = resp.url_form_action;
        formVisanet.method  = 'post';

        visanet.style.display = 'none';

        visanet.appendChild(formVisanet);
        
        scriptDynamic.onload = function(event) {
          event.preventDefault();
          const botonVisanet = formVisanet.getElementsByTagName('button')[0];
          botonVisanet.click();
        }

        scriptDynamic.src = resp.url_javascript;
        scriptDynamic.type = 'text/javascript';
        scriptDynamic.setAttribute('data-sessiontoken', resp.session_key);
        scriptDynamic.setAttribute('data-channel', resp.channel);
        scriptDynamic.setAttribute('data-merchantid', resp.codigo_comerciante);
        scriptDynamic.setAttribute('data-merchantlogo', resp.url_logo_inmobiliaria);
        scriptDynamic.setAttribute('data-purchasenumber', resp.codigo_cobro);
        scriptDynamic.setAttribute('data-amount', resp.monto_cobro.toFixed(2).toString());
        scriptDynamic.setAttribute('data-expirationminutes', '5');
        scriptDynamic.setAttribute('data-timeouturl', 'https://staging.reserva.peru.saladeventasdigital.com/#/welcome/' + this.apiKey + '/' +this.hashCotizacion);

        formVisanet.appendChild(scriptDynamic);
      },
      error => {
        console.log('Error al iniciar servicio de Visanet', error);
        this.webpayLoading = false;
        this.disabledPayButton = true;
      }
    );
  }

  formularioPagoVisanet(): void {
    this.iniciarFormularioVisanet();
  }

}