import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { saveClientData } from '../../models/saveClientData.model';

@Injectable({
  providedIn: 'root'
})
export class SaveDataService {

  url: string;
  apyKey: string;
  postId: any


  // /reserva/clientes/naturales/{hash} 
  constructor(protected http: HttpClient) { 
  
   }

  saveDataService(apikey: string, hash: string, body: any): Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
      const bodyParse = JSON.stringify(body);
      return this.http.put(environment.urlApi + '/reserva/clientes/naturales/'+ hash, bodyParse,{headers});
  }
}
