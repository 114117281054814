import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PreviousReservationModel } from '../../models/previousReservation.model';

@Injectable({
  providedIn: 'root'
})
export class PreviousReservationDataService {

  url: string;
  body: any;
  apyKey: string;

  constructor(protected http: HttpClient) {  }

  getReservations(apikey: string, hash: string): Observable<PreviousReservationModel> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
    return this.http.get<PreviousReservationModel>(environment.urlApi + '/reserva/'+ hash + '/datos-previos-a-reservar',{headers});
    
  }
}
