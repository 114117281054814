import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'tipoCuota'
})
export class TipoCuotaPipe implements PipeTransform{

  transform(value: string): string {
    switch (value) {
      case 'VD':
        return 'Venta débito';
        break;
      case 'VN':
        return 'Venta normal';
        break;
      case 'VC':
        return 'Venta en cuotas';
        break;
      case 'SI':
        return 'Tres cuotas sin interés';
        break;
      case 'S2':
        return 'Dos cuotas sin interés';
        break;
      case 'NC':
        return 'Cuotas sin interes';
        break;
      case 'VP':
        return 'Venta prepago';
        break;

    }
  }
}