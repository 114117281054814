import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebpayService } from '../../services/webpay/webpay.service';
import { WebPayModel } from '../../models/webpay.model';
import { HttpClient , HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-webpay',
  templateUrl: './webpay.component.html',
  styleUrls: ['./webpay.component.css']
})
export class WebpayComponent implements OnInit {

  isLoading: boolean;
  apiKey : string;
  hashCotizacion: string;
  webpayData: WebPayModel;
  token_web: string;
  body: any;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private webPayService: WebpayService,
    protected http: HttpClient,
  ) {
    this.apiKey = this._route.snapshot.paramMap.get('apikey');
    this.hashCotizacion = this._route.snapshot.paramMap.get('hash');
    
  }

  ngOnInit() {
    this.isLoading = true;

    this.webPayService.getWebpay(this.apiKey, this.hashCotizacion)
    .subscribe(
      resp => {
        this.webpayData = resp;
      },
      error => {
        console.log('Error al cargar servicio de webpay', error);
      }
    );

  }

}
