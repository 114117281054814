import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, LOCALE_ID} from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { QuotationDataService } from "../../services/quotation-data.service";
import { QuotationDataModel } from "../../models/quotation-data.model";
import * as moment from 'moment';
import { ComunaService } from '../../services/comuna/comuna.service';
import { PreviousReservationDataService } from '../../services/previousReservationData/previous-reservation-data.service';
import { RegionsService } from '../../services/regions/regions.service';
import { RegionModel } from '../../models/region.model';
import { ComunaModel } from '../../models/comuna.model';
import { PreviousReservationModel } from '../../models/previousReservation.model';
import { WebpayService } from '../../services/webpay/webpay.service';
import { SaveDataService } from '../../services/saveData/save-data.service';
import { ProductBlockService } from '../../services/productBlock/product-block.service';
import { AppDateAdapter, APP_DATE_FORMATS } from './date.adapter';
import { TimerComponent } from '../../shared/timer/timer.component';
import { environment } from 'src/environments/environment';
import { DepartamentoModel } from '../../models/departamento.model';
import { DepartamentosService } from '../../services/departamentos/departamentos.service';
import { ProvinciaModel } from '../../models/provincia.model';
import { DistritoModel } from '../../models/distrito.model';
import { ProvinciasService } from '../../services/provincias/provincias.service';
import { DistritosService } from '../../services/distritos/distritos.service';
import { QuotationDataPeruService } from '../../services/quotation-data-peru/quotation-data-peru.service';
import { QuotationDataPeruModel } from '../../models/quotation-data-peru.model';
import { VisanetService } from '../../services/visanet/visanet.service';
import { VisanetModel } from '../../models/visanet.model';
import { SaveDataPeruService } from '../../services/saveDataPeru/save-data-peru.service';
import { RxTranslation, translate } from "@rxweb/translate";

@Component({
  selector: "app-reservation",
  templateUrl: "./reservation.component.html",
  styleUrls: ["./reservation.component.css"],
  providers: [
    {
        provide: DateAdapter , useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class ReservationComponent implements OnInit {

  @translate() multilingualContent:{[key:string]:any};
  @ViewChild(TimerComponent, { static: true }) timerComponent: TimerComponent;
  isLoading: boolean;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  quotationData: QuotationDataModel;
  quotationDataPeru: QuotationDataPeruModel;
  regionsData: RegionModel[];
  comunaData: ComunaModel[];
  departamentoData: DepartamentoModel[];
  provinciaData: ProvinciaModel[];
  distritoData: DistritoModel[];
  previousReservation: PreviousReservationModel;
  clientFirstName: string;
  clientFatherName: string;
  clientMotherName: string;
  clientMail: string;
  clientMobileNumber: string;
  clientAddress: string;
  clientAddressNumber: string;
  clientComplement: string;
  clientNeighborhood: any;
  clientCity: string;
  clientStatePeru: string;
  clientCityPeru: number;
  clientNeighborhoodPeru: number;
  picker: any;
  isChecked: boolean = false;
  maxDate: any = new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDate()) ;
  minDate: any = new Date(new Date().getFullYear()-100);
  dateHoy: any = new Date();
  dateValue: any = moment();
  daysToExp: any;
  dateExp: any;
  acceptTerms: boolean;
  apiKey: string;
  hashCotizacion: string;
  disabledPayButton: boolean = true;
  webpayLoading: boolean = false;
  selectedRegion: number = 0;
  selectedComuna: number = 0;
  selectedDepartamento: number = 0;
  selectedProvincia: number = 0;
  selectedDistrito: number = 0;
  comunas =[];
  provincias = [];
  distritos = [];
  webpayData: any;
  visanetData: VisanetModel;
  submitted: boolean = false;
  webpayUrl: string;
  webpayToken: string;
  reservaPagada: boolean = false;
  pagoFallido: boolean = false;
  leng: string;
  showChile: boolean = false;
  showPeru: boolean = false;
  visanetUrlFormAction: string;
  visanetUrlJS: string;
  visanetSessionKey: string;
  visanetChannel: string;
  visanetCodigoComerciante: string;
  visanetUrlLogoInmobiliaria: string;
  visanetCodigoCobro: string;
  visanetMontoCobro: number;

  constructor(
    private router: Router,
    private quotationDataService: QuotationDataService,
    private quotationDataPeruService: QuotationDataPeruService,
    private formBuilder: FormBuilder,
    private comunaService: ComunaService,
    private reservationService : PreviousReservationDataService,
    private regionsService: RegionsService,
    private _route: ActivatedRoute,
    private webPayService: WebpayService,
    private visanetService: VisanetService,
    private saveDataClient: SaveDataService,
    private saveDataClientPeru: SaveDataPeruService,
    private productBlockService: ProductBlockService,
    private departamentosService: DepartamentosService,
    private provinciasService: ProvinciasService,
    private distritosService: DistritosService,
    private traslation: RxTranslation,
  ) {
    this.apiKey = this._route.snapshot.paramMap.get('apikey');
    this.hashCotizacion = this._route.snapshot.paramMap.get('hash');
    this.thirdFormGroup = this.formBuilder.group({
    acceptTerms:['', Validators.requiredTrue]
  });
}


  ngOnInit() {
    this.isLoading = true;
    this.changeCountry();
    this.getReserva();
    this.productBlock();
    this.getCotizacionData(this.apiKey, this.hashCotizacion);
    this.getCotizacionDataPeru(this.apiKey, this.hashCotizacion);
  }


  changeCountry(){
    this.leng = environment.leng;
    switch(this.leng){
      case 'es-CL': this.showChile = true;
        this.getRegiones();
        this.getComunas();
        break;
      case 'es-PE': 
      case 'es-PE-DL': 
        this.showPeru = true;
        this.getDepartamentos();
        this.getProvincias();
        this.getDistritos();
        break;
    }
  }

  getCotizacionData(apiKey: string, hashCotizacion: string): void {
    this.quotationDataService.getQuotationData(this.apiKey, this.hashCotizacion).subscribe(
      (response) => {
        this.quotationData = response;
        this.checkPago();
        this.traslation.change(this.quotationData.lenguaje);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getCotizacionDataPeru(apiKey: string, hashCotizacion: string): void {
    this.quotationDataPeruService.getQuotationDataPeru(this.apiKey, this.hashCotizacion).subscribe(
      (response) => {
        this.quotationDataPeru = response;
        this.checkPagoPeru();
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getRegiones() {
    this.regionsService.getRegions(this.apiKey, this.hashCotizacion).subscribe(
      resp => {
        this.regionsData = resp;         
      },
      error => {
        console.log('Erro al Cargar las Regiones', error);
      }
    );
  }

 
  getComunas() {
    this.comunaService.getComunas(this.apiKey, this.hashCotizacion).subscribe(
      resp => {
        this.comunaData = resp;
      },
      error => {
        console.log('Error al Cargar las Comunas', error);
      }
    );
  }

    
  getDepartamentos() {
    this.departamentosService.getDepartamentos(this.apiKey, this.hashCotizacion).subscribe(
        resp => {
          this.departamentoData = resp;
        },
        error => {
          console.log('Erro al Cargar los Departamentos', error);
        }
    );
  }

  
  getProvincias() {
    this.provinciasService.getProvincias(this.apiKey, this.hashCotizacion).subscribe(
        resp => {
          this.provinciaData = resp;
        },
        error => {
          console.log('Erro al Cargar las provincias', error);
        }
    );
  }

  getDistritos() {
    this.distritosService.getDistritos(this.apiKey, this.hashCotizacion).subscribe(
        resp => {
          this.distritoData = resp;
        },
        error => {
          console.log('Erro al Cargar los distritos', error);
        }
    );
  }

  getReserva() {
    this.reservationService.getReservations(this.apiKey, this.hashCotizacion)
      .subscribe(
        resp => {
          this.previousReservation = resp;
          this.daysToExp = this.previousReservation.duracionOferta;
          this.calcDays();
        },
        error => {
          console.log('Error al Cargar los Datos Previos a la Reserva', error);
        }
    );
  }

  productBlock() {
    this.productBlockService.blockProduct(this.apiKey, this.hashCotizacion)
      .subscribe(
        resp => { },
        error => {
          console.log('Error al Bloquear el Producto Principal', error);
        }
    );
  }

  setFormData() {
    this.leng = environment.leng;
    this.clientFirstName = this.quotationData.cliente.natNombre;
    this.clientFatherName = this.quotationData.cliente.natApellidoPaterno;
    this.clientMotherName = this.quotationData.cliente.natApellidoMaterno;
    this.clientMail = this.quotationData.cliente.natEmail;
    this.clientMobileNumber = this.quotationData.cliente.natFonoCelular;
    this.clientAddress = this.quotationData.cliente.natDirCalle;
    this.clientAddressNumber = this.quotationData.cliente.natDirNumero;
    this.clientComplement = this.quotationData.cliente.natDirDepto;
    this.picker = this.quotationData.cliente.fechaNacimiento;
 
    if(this.leng === 'es-CL' && this.quotationData.cliente.glosaRegion !== null) {
      this.clientCity = this.quotationData.cliente.glosaRegion;
      this.selectedRegion = 0;
      this.selectedComuna = 0;
      this.clientNeighborhood = "";
      this.findRegion(this.clientCity);
    }
    if(this.leng === 'es-CL' && this.quotationData.cliente.glosaRegion !== null && this.quotationData.cliente.natDirComuna !== null ){
      this.clientCity = this.quotationData.cliente.glosaRegion;
      this.clientNeighborhood = this.quotationData.cliente.natDirComuna;
      this.selectedRegion = 0;
      this.selectedComuna = 0;
      this.findRegion(this.clientCity);
    }
  }

  setFormDataPeru() {
    this.clientFirstName = this.quotationDataPeru.cliente.natNombre;
    this.clientFatherName = this.quotationDataPeru.cliente.natApellidoPaterno;
    this.clientMotherName = this.quotationDataPeru.cliente.natApellidoMaterno;
    this.clientMail = this.quotationDataPeru.cliente.natEmail;
    this.clientMobileNumber = this.quotationDataPeru.cliente.natFonoCelular;
    this.clientAddress = this.quotationDataPeru.cliente.natDirCalle;
    this.clientAddressNumber = this.quotationDataPeru.cliente.natDirNumero;
    this.clientComplement = this.quotationDataPeru.cliente.natDirDepto;
    this.picker = this.quotationDataPeru.cliente.fechaNacimiento;
 
    if(this.leng === 'es-PE' || this.leng === 'es-PE-DL' && this.quotationDataPeru.cliente.glosaDepartamento !== null){
      this.clientStatePeru = this.quotationDataPeru.cliente.glosaDepartamento;
      this.findDepartamento(this.clientStatePeru);
    }
    if(this.leng === 'es-PE' || this.leng === 'es-PE-DL' && this.quotationDataPeru.cliente.glosaDepartamento !== null && this.quotationDataPeru.cliente.glosaProvincia !== null){
      this.clientStatePeru = this.quotationDataPeru.cliente.glosaDepartamento;
      this.clientNeighborhoodPeru = this.quotationDataPeru.cliente.natDirComuna;
      this.findDepartamento(this.clientStatePeru);
      this.findProvincia(this.quotationDataPeru.cliente.glosaProvincia);
    }
  }

  calcDays() {
    this.dateExp =  new Date();
    this.dateExp.setDate(this.dateExp.getDate() + this.daysToExp);
  }
  
  initFormControl() {
    this.leng = environment.leng;
    if(this.leng === 'es-CL'){
      this.firstFormGroup = this.formBuilder.group({
        clientFirstName: [ this.clientFirstName, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientFatherName: [this.clientFatherName, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientMotherName: [this.clientMotherName, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientMail: [this.clientMail, [ Validators.required, Validators.email , Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
        picker: [this.picker, Validators.required ],
        clientMobileNumber: [this.clientMobileNumber, [ Validators.required, Validators.pattern('[\+0-9]*') ]],
        clientAddress: [this.clientAddress, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientAddressNumber: [this.clientAddressNumber, [ Validators.required, Validators.pattern('[0-9]*') ]],
        clientComplement: [this.clientComplement],
        clientCity: [this.selectedRegion, Validators.required],
        clientNeighborhood: [this.clientNeighborhood, Validators.required]
      });
    }
    if(this.leng === 'es-PE' || this.leng === 'es-PE-DL'){
      this.firstFormGroup = this.formBuilder.group({
        clientFirstName: [ this.clientFirstName, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientFatherName: [this.clientFatherName, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientMotherName: [this.clientMotherName, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientMail: [this.clientMail, [ Validators.required, Validators.email , Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
        picker: [this.picker, Validators.required ],
        clientMobileNumber: [this.clientMobileNumber, [ Validators.required, Validators.pattern('[\+0-9]*') ]],
        clientAddress: [this.clientAddress, [ Validators.required, Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$') ]],
        clientAddressNumber: [this.clientAddressNumber, [ Validators.required, Validators.pattern('[0-9]*') ]],
        clientComplement: [this.clientComplement],
        clientStatePeru: [this.selectedDepartamento, Validators.required],
        clientCityPeru: [this.clientCityPeru, Validators.required ],
        clientNeighborhoodPeru: [this.clientNeighborhoodPeru, Validators.required ]
      });
    }
  }

  checkPago() {
    if (this.quotationData.deuda) {
      if (this.quotationData.deuda.estado === 'pagada') {
        this.reservaPagada = true;
      } else {
        this.pagoFallido = true;
      }
    } else {
      setTimeout(() => {
        this.setFormData();
        this.initFormControl();
        this.isLoading = false;
      }, 500);
    }
  }


  checkPagoPeru() {
    if (this.quotationDataPeru.cobro_visanet) {
      if (this.quotationDataPeru.cobro_visanet.estado === 'aprobado') {
        this.reservaPagada = true;
      } else {
        this.pagoFallido = true;
        this.goToRetry();
      }
    } else {
      setTimeout(() => {
        this.setFormDataPeru();
        this.initFormControl();
        this.isLoading = false;
      }, 500);
    }
  }

  get f() {
    return this.firstFormGroup.controls;
  }

  onSelectedRegion(idRegion:number){
    this.selectedRegion = idRegion;
    this.selectedComuna = 0;
    this.comunas = this.comunaData.filter((item:ComunaModel)=> {
      return item.idRegion === Number(idRegion);
    });
  }

  findRegion(glosaRegion:string) {
    const regiones = this.regionsData.find((item: RegionModel) => item.glosaRegion === glosaRegion);
    this.selectedRegion = regiones.idRegion;
    this.onSelectedRegion(this.selectedRegion);
  }

  onSelectedDepartamento(idDepartamento:number){
    this.selectedDepartamento = idDepartamento;
    this.selectedProvincia = 0;
    this.selectedDistrito = 0;
    this.provincias = this.provinciaData.filter((item:ProvinciaModel)=> {
      return item.idDepartamento === Number(idDepartamento);
    });
  }

  onSelectedProvincia(idProvincia:number){
    this.selectedDistrito = 0;
    this.distritos = this.distritoData.filter((item:DistritoModel) => {
      return item.idProvincia === Number(idProvincia);
    })
  }
    
  findDepartamento(glosaDepartamento:string) {
    const departamentos = this.departamentoData.find((item: DepartamentoModel) => item.glosaDepartamento === glosaDepartamento);
    this.selectedDepartamento = departamentos.idDepartamento;
    this.onSelectedDepartamento(this.selectedDepartamento);
  }

  findProvincia(glosaProvincia:string) {
    const provincias = this.provinciaData.find((item: ProvinciaModel) => item.glosaProvincia === glosaProvincia);
    this.clientCityPeru = provincias.idProvincia;
    this.onSelectedProvincia(this.clientCityPeru);
  }

  enableButton() {
    if(!this.thirdFormGroup.controls.acceptTerms.value){
      this.disabledPayButton = true;
      
    } else {
      this.disabledPayButton = false;
    }
  }

  getWebpayToken() {
    this.webpayLoading = true;
    this.disabledPayButton = true;
    this.webPayService.getWebpay(this.apiKey, this.hashCotizacion).subscribe(
      resp => {
      this.webpayData = resp;
      this.webpayToken = this.webpayData.token;
      this.webpayUrl = this.webpayData.url;
      setTimeout(() => {
        this.sendToWebpay();
      }, 800);
    },
    error => {
      console.log('Error al cargar servicio de webpay', error);
      this.webpayLoading = false;
      this.disabledPayButton = true;
      }
    );
  }

  sendToWebpay(): void {
    const button: HTMLElement = document.getElementById('submitWebpayButton') as HTMLElement;
    button.click();
  }

  iniciarFormularioVisanet() {
    this.webpayLoading = true;
    this.disabledPayButton = true;
    this.visanetService.getVisanet(this.apiKey, this.hashCotizacion).subscribe(
      resp => {
        
        const visanet       = document.getElementById('botonPagoVisanet');
        const formVisanet   = document.createElement('form');
        const scriptDynamic = document.createElement('script');

        formVisanet.action  = resp.url_form_action;
        formVisanet.method  = 'post';

        visanet.style.display = 'none';

        visanet.appendChild(formVisanet);
        
        scriptDynamic.onload = function(event) {
          event.preventDefault();
          const botonVisanet = formVisanet.getElementsByTagName('button')[0];
          botonVisanet.click();
        }

        scriptDynamic.src = resp.url_javascript;
        scriptDynamic.type = 'text/javascript';
        scriptDynamic.setAttribute('data-sessiontoken', resp.session_key);
        scriptDynamic.setAttribute('data-channel', resp.channel);
        scriptDynamic.setAttribute('data-merchantid', resp.codigo_comerciante);
        scriptDynamic.setAttribute('data-merchantlogo', resp.url_logo_inmobiliaria);
        scriptDynamic.setAttribute('data-purchasenumber', resp.codigo_cobro);
        scriptDynamic.setAttribute('data-amount', resp.monto_cobro.toFixed(2).toString());
        scriptDynamic.setAttribute('data-expirationminutes', '5');
        scriptDynamic.setAttribute('data-timeouturl', 'https://staging.reserva.peru.saladeventasdigital.com/#/welcome/' + this.apiKey + '/' +this.hashCotizacion);

        formVisanet.appendChild(scriptDynamic);
      },
      error => {
        console.log('Error al iniciar servicio de Visanet', error);
        this.webpayLoading = false;
        this.disabledPayButton = true;
      }
    );
  }

  submit() {
    this.submitted = true;
    if (this.firstFormGroup.invalid) {
      return;
    } else {
      const body = {
        nombre: this.firstFormGroup.get('clientFirstName').value,
        apellidoPaterno: this.firstFormGroup.get('clientFatherName').value,
        apellidoMaterno: this.firstFormGroup.get('clientMotherName').value, 
        email: this.firstFormGroup.get('clientMail').value, 
        celular: this.firstFormGroup.get('clientMobileNumber').value, 
        fechaNacimiento: this.firstFormGroup.get('picker').value,
        direccion: this.firstFormGroup.get('clientAddress').value,
        numero: parseInt(this.firstFormGroup.get('clientAddressNumber').value), 
        deptoCasa: this.firstFormGroup.get('clientComplement').value, 
        idRegion: this.firstFormGroup.get('clientCity').value, 
        idComuna: this.firstFormGroup.get('clientNeighborhood').value,
      };
      console.log("cliente-valido Chile", body); 
      this.saveDataClient.saveDataService(this.apiKey, this.hashCotizacion, body).subscribe(
        resp => {
          console.log("respuesta", resp);
        },
        (error) => {
          console.log("error", error);
        }
      )
    }
  }

  submitPeru() {
    this.submitted = true;
    if (this.firstFormGroup.invalid) {
      return;
    } else {
      const body = {
        nombre: this.firstFormGroup.get('clientFirstName').value,
        apellidoPaterno: this.firstFormGroup.get('clientFatherName').value,
        apellidoMaterno: this.firstFormGroup.get('clientMotherName').value, 
        email: this.firstFormGroup.get('clientMail').value, 
        celular: this.firstFormGroup.get('clientMobileNumber').value, 
        fechaNacimiento: this.firstFormGroup.get('picker').value,
        direccion: this.firstFormGroup.get('clientAddress').value,
        numero: parseInt(this.firstFormGroup.get('clientAddressNumber').value), 
        deptoCasa: this.firstFormGroup.get('clientComplement').value, 
        idDepartamento: this.firstFormGroup.get('clientStatePeru').value,
        idProvincia: this.firstFormGroup.get('clientCityPeru').value,
        idDistrito: this.firstFormGroup.get('clientNeighborhoodPeru').value
      };
      console.log("cliente-valido Peru", body);
      this.saveDataClientPeru.saveDataService(this.apiKey, this.hashCotizacion, body).subscribe(
        resp => {
          console.log("respuesta submit Peru", resp);
        },
        (error) => {
          console.log("error", error);
        }
      )
    }
  }

  formularioPagoVisanet(): void {
    this.iniciarFormularioVisanet();
  }

  backToWelcome(): void {
    this.router.navigate(['welcome/' + this.apiKey + '/' + this.hashCotizacion])
  }

  goToReceipt(): void {
    this.router.navigate(['reservation/receipt/' + this.apiKey + '/' + this.hashCotizacion])
  }

  goToRetry(): void {
    console.log('Entró al retry');
    this.router.navigate(['reservation/retry/' + this.apiKey + '/' + this.hashCotizacion])
  }
}

