import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment'; 
import { RegionModel } from '../../models/region.model';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  url: string;

  constructor(protected http: HttpClient) { 
    this.url = environment.urlApi + '/regiones';
  }

  getRegions(apikey: string, hash: string): Observable<RegionModel[]> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
    return this.http.get<RegionModel[]>(this.url,{headers});
  }
}
