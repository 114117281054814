import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule }    from '@angular/common/http';
import { WelcomeComponent } from './components/pages/welcome/welcome.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderWelcomeComponent } from './components/shared/header-welcome/header-welcome.component';
import { ReservationComponent } from './components/pages/reservation/reservation.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatSnackBarModule, MatAutocompleteModule, MatSpinner } from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { CityService } from './components/services/city/city.service';
import { ComunaService } from './components/services/comuna/comuna.service';
import { PreviousReservationDataService } from './components/services/previousReservationData/previous-reservation-data.service';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { ReceiptComponent } from './components/pages/receipt/receipt.component';
import { QuotationDataService } from './components/services/quotation-data.service';
import { TimerComponent } from './components/shared/timer/timer.component';
import { RetryPaymentComponent } from './components/pages/retry-payment/retry-payment.component';
import { WebpayComponent } from './components/pages/webpay/webpay.component';
import { WebpayService } from './components/services/webpay/webpay.service';
import { PipesModule} from "./components/pipes/pipes.module";
import { SaveDataService } from './components/services/saveData/save-data.service';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CaptchaService } from './components/services/captcha/captcha.service';
import { ProductBlockService } from './components/services/productBlock/product-block.service';
import { BackgroundReservaService } from './components/services/backgroundReserva/background-reserva.service';
import { HeaderStepsComponent } from './components/shared/header-steps/header-steps/header-steps.component';
import { SaveDataPeruService } from './components/services/saveDataPeru/save-data-peru.service';
import { RxTranslateModule } from "@rxweb/translate"



@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    FooterComponent,
    HeaderWelcomeComponent,
    ReservationComponent,
    ReceiptComponent,
    TimerComponent,
    RetryPaymentComponent,
    WebpayComponent,
    HeaderStepsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatMomentDateModule,
    MatSelectModule,
    PipesModule,
    RecaptchaModule,  
    RecaptchaFormsModule,
    RxTranslateModule.forRoot({filePath:'assets/i18/{{language-code}}.json'}),
  ],
  providers: [
    CityService,
    ComunaService,
    PreviousReservationDataService,
    QuotationDataService,
    WebpayService,
    SaveDataService,
    SaveDataPeruService,
    CaptchaService,
    ProductBlockService,
    BackgroundReservaService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
