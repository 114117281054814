<div class="container-fluid mt-4" id="productoReservado" *ngIf="validityOfQuotation">
  <app-header-steps></app-header-steps>
  <div class="row">
    <div class="col-12 px-0 px-md-4">
      <div class="text-center">
        <div class="spinner-border" role="status" *ngIf="isLoading">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
      <div id="redirectPage" *ngIf="!isLoading">
        <div class="container-fluid content p-4" >
          <div class="col-12 align-content-center text-center mt-4">
            <h2 class="txt-title p-3">¡Oh! Esta cotización no está vigente</h2>
            <img src="../../../../assets/img/noUnidad.svg" alt="logo-proyecto">
            <h5 class="mt-2 mb-2 pb-4">¡Cotización fuera de plazo!</h5>
            <p class="mt-2 mb-2">Te invitamos a cotizar nuevamente.</p>
          </div>
          <div class="col-md-12 w-100 p-2 my-2" ></div>
          <div class="row justify-content-between align-items-center pt-4 pt-sm-0 pb-2">
            <div class="col-md-6 offset-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!validityOfQuotation">
<div class="container-fluid mt-4" id="productoReservado" *ngIf="lockedProducto">
  <app-header-steps></app-header-steps>
  <div class="row">
    <div class="col-12 px-0 px-md-4">
      <div class="text-center">
        <div class="spinner-border" role="status" *ngIf="isLoading">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
      <div id="redirectPage" *ngIf="!isLoading">
        <div class="container-fluid content p-4" >
          <div class="col-12 align-content-center text-center mt-4">
            <h2 class="txt-title p-3">{{multilingualContent.welcome.tituloNoDisponible}}</h2>
            <img src="../../../../assets/img/noUnidad.svg" alt="logo-proyecto">
            <h5 class="mt-2 mb-2 pb-4">¡Hola {{quotationData.cliente.natNombre}} {{quotationData.cliente.natApellidoPaterno}}!
            </h5>
            <p class="mt-2 mb-2 text-size-18">Parece que hay mucha concurrencia en este proyecto,
            </p>
            <p class="mt-2 mb-2">te invitamos a cotizar otra unidad.
            </p>
          </div>
          <div class="col-md-12 w-100 p-2 my-2" >
          </div>
          <div class="row justify-content-between align-items-center pt-4 pt-sm-0 pb-2">

            <div class="col-md-6 offset-3">
              <a
                type="button"
                alt="link sitio proyecto"
                href="{{quotationData.proyecto.urlProyecto}}"
                class="btn btn-dark btn-lg mt-4 mr-2 btn-submit w-100" style="padding: 1rem !important;">
                Cotizar otra unidad
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid pb-1" id="conditions" *ngIf="!lockedProducto">
  <app-header-welcome *ngIf="!isLoading"></app-header-welcome>
  <div class="container content" >
    <div class="spinner-border" role="status" *ngIf="isLoading">
      <span class="sr-only">Cargando...</span>
    </div>
    <div id="welcomePage" *ngIf="!isLoading">
      <div class="row">
        <div class="col-xs-12 col-sm-12 p-4">
          <h3 class="txt-title mb-4">¡Hola {{quotationData.cliente.natNombre}} {{quotationData.cliente.natApellidoPaterno}}!</h3>

          <div class="txt-conditions mb-0" >
            <ul id="conditions-list" class="list-group text-size-14 ml-0">
              <li class="list-group-item borderless pl-0 pr-0 pb-0 d-flex align-content-center">
                <span class="material-icons size-md pr-2">done</span>
                <p>{{multilingualContent.welcome.ListadoCondicion1}}</p> 
              </li>
              <li class="list-group-item borderless pl-0 pr-0 pb-0 d-flex align-content-center">
                <span class="material-icons size-md pr-2">done</span>
                <p>{{multilingualContent.welcome.ListadoCondicion2a}} <span class="txt-size-16" style="font-weight: 600;">{{previousReservation.valorReserva | number_format: quotationData.lenguaje }}</span>{{multilingualContent.welcome.ListadoCondicion2b}}</p>
              </li>
              <li class="list-group-item borderless pl-0 pr-0 pb-0 d-flex align-content-center">
                <span class="material-icons size-md pr-2">done</span>
                <p>{{multilingualContent.welcome.ListadoCondicion3}}</p>
              </li>
            </ul>
          </div>
          <div class="confirmation mt-4">
            <h3 class="txt-subtitle" >{{multilingualContent.welcome.idClienteCondicionesUno}}</h3>
            <p>{{multilingualContent.welcome.idClienteCondicionesDos}}</p> 
            <div class="row content-id p-2 m-2">
              <div class="col-lg-1 col-icon pl-4 pr-4 mt-4 align-items-center">
                <span class="material-icons">
                  vpn_key
                </span>
              </div>
              <div class="col-sm-3 p-2" >
                <h5>
                  <span><small>{{multilingualContent.welcome.idClienteCondicionesTres}}</small></span>
                </h5>
                <h4 class="txt-subtitle" *ngIf="showChile">{{quotationData.cliente.natRut}}-{{quotationData.cliente.natDvRut}}</h4>
                <h4 class="txt-subtitle" *ngIf="showPeru">{{quotationData.cliente.natRut}}</h4>
              </div>
              <div class="col-sm-8 p-2 mt-3">
                <p >
                  <span class="txt-subtitle"></span>
                  {{multilingualContent.welcome.idClienteCondicionesCuatro}}
                  <a href="{{quotationData.proyecto.urlProyecto}}" alt="link cotizacion">aquí</a>
                </p>
              </div>
            </div>
            <form [formGroup]="welcomeFormGroup" ngNativeValidate>
              <div class="row">
               
                <div class="col-xs-12 col-md-6 mt-3 d-flex align-items-center">
                  <mat-slide-toggle type="checkbox" color="primary" required formControlName="idConfirmed">
                    <span class="txt-subtitle "></span><strong>Yo confirmo mi identificación</strong>
                  </mat-slide-toggle>
                </div>
                <div class="col-xs-12 col-md-6 mt-3 w-100 d-flex justify-content-end" >
                  <re-captcha (resolved)="resolved($event)"
                    siteKey="6Lc-78AZAAAAALDr3I0uqcOEH_taZtZ5xzTkeV0n">  
                  </re-captcha>
                </div>
             
              </div>    
              <div class="row">
                <div class="col-sm-6">
                <a
                  type="button"
                  alt="link sitio proyecto"
                  href="{{quotationData.proyecto.urlProyecto}}"
                  class="btn btn-outline-dark btn-lg mt-4 mr-2 btn-cancel w-100" style="padding: 1rem !important;">
                  Salir
                </a>
                </div>
                <div class="col-sm-6">
                  <button type="submit" class="btn btn-dark btn-lg mt-4 mr-2 btn-submit w-100" (click)="submit()">
                    Sí, quiero continuar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>