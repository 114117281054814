import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment'; 
import { BackgroundReservaModel } from '../../models/backgroundReserva.model';

@Injectable({
  providedIn: 'root'
})
export class BackgroundReservaService {

  url: string;

  constructor(protected http: HttpClient) {
    this.url = environment.urlApi + '/reserva/background/imagen';
   }

  getBackground(apikey: string): Observable<BackgroundReservaModel[]> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
    return this.http.get<BackgroundReservaModel[]>(this.url,{headers});
  }
}
