import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({
  name: 'date_format'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: Date, country: string): string {
    let formatter = null;
    
    switch (country) {
      default:
      case 'es-CL':
        formatter = date.toLocaleString('es-CL', { 
          timeZone: 'America/Santiago' 
        });
        break;
      case 'es-PE': 
      case 'es-PE-DL': 
        formatter = date.toLocaleString('es-LI', { 
          timeZone: 'America/Lima'
        });
        break;
    }

    formatter = formatter.replace(/\//g, '-');

    return formatter;
  }
}