import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'tipoPago'
})
export class TipoPagoPipe implements PipeTransform{

  transform(value: string): string {
    switch (value) {
      case 'VD':
        return 'Débito';
        break;
      case 'VN':
        return 'Crédito';
        break;
      case 'VC':
        return 'Crédito';
        break;
      case 'SI':
        return 'Crédito';
        break;
      case 'S2':
        return 'Crédito';
        break;
      case 'NC':
        return 'Crédito';
        break;
      case 'VP':
        return 'Débito';
        break;

    }
  }
}
