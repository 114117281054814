import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'; 
import { DepartamentoModel } from '../../models/departamento.model';

@Injectable({
  providedIn: 'root'
})
export class DepartamentosService {

  url: string;

  constructor(protected http: HttpClient) { 
    this.url = environment.urlApi + '/departamentos';
    console.log(environment.urlApi);
  }

  getDepartamentos(apikey: string, hash: string): Observable<DepartamentoModel[]> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
      console.log(this.url);
      console.log(headers);
    return this.http.get<DepartamentoModel[]>(this.url,{headers});
  }


}
