import { Component, OnInit } from '@angular/core';
import { translate } from "@rxweb/translate";

@Component({
  selector: 'app-header-welcome',
  templateUrl: './header-welcome.component.html',
  styleUrls: ['./header-welcome.component.css']
})
export class HeaderWelcomeComponent implements OnInit {

  @translate() multilingualContent: { [key: string]: any };

  constructor( ) { }

  ngOnInit() { }

}
