import { Component, OnInit } from '@angular/core';
import { QuotationDataService } from '../../services/quotation-data.service';
import { QuotationDataModel } from '../../models/quotation-data.model';
import { Router, ActivatedRoute } from "@angular/router";
import { PreviousReservationModel } from '../../models/previousReservation.model';
import { PreviousReservationDataService } from '../../services/previousReservationData/previous-reservation-data.service';
import { environment } from 'src/environments/environment';
import { QuotationDataPeruModel } from '../../models/quotation-data-peru.model';
import { QuotationDataPeruService } from '../../services/quotation-data-peru/quotation-data-peru.service';
import { RxTranslation, translate } from "@rxweb/translate";

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  @translate() multilingualContent:{[key:string]:any};
  isLoading: boolean;
  quotationData: QuotationDataModel;
  quotationDataPeru: QuotationDataPeruModel;
  previousReservation: PreviousReservationModel;
  maxDate =  new Date();
  daysToExp: any;
  dateExp: any;
  apiKey: string;
  hashCotizacion: string;
  leng: string;
  showChile: boolean = false;
  showPeru: boolean = false;

  constructor(
    private router: Router,
    private quotationDataService: QuotationDataService,
    private quotationDataPeruService: QuotationDataPeruService,
    private reservationService: PreviousReservationDataService,
    private _route: ActivatedRoute,
    private traslation: RxTranslation,
  ) {
    this.apiKey = this._route.snapshot.paramMap.get('apikey');
    this.hashCotizacion = this._route.snapshot.paramMap.get('hash');
  }

  ngOnInit() {
    this.isLoading = true;
    this.changeCountry();
    this.getReservationData();
  }

  changeCountry(){
    this.leng = environment.leng;
    switch(this.leng){
      case 'es-CL': 
        this.showChile = true;
        this.getCotizacionData(this.apiKey, this.hashCotizacion);
        break;
      case 'es-PE': 
      case 'es-PE-DL': 
        this.showPeru = true;
        this.getCotizacionDataPeru(this.apiKey, this.hashCotizacion);
        this.getCotizacionData(this.apiKey, this.hashCotizacion);
        break;
    }
  }

  getCotizacionData(apiKey: string, hashCotizacion: string): void {
    this.quotationDataService.getQuotationData(this.apiKey, this.hashCotizacion).subscribe(
      (response) => {
        this.quotationData = response;
        this.traslation.change(this.quotationData.lenguaje);
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  getCotizacionDataPeru(apiKey: string, hashCotizacion: string): void {
    this.quotationDataPeruService.getQuotationDataPeru(this.apiKey, this.hashCotizacion).subscribe(
      (response) => {
        this.quotationDataPeru = response;
        if(response.cobro_visanet.estado != 'aprobado'){
          this.goToRetry();
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getReservationData() {
    this.reservationService.getReservations(this.apiKey, this.hashCotizacion).subscribe(
      response => {
        this.previousReservation = response;
        this.daysToExp = this.previousReservation.duracionOferta;
        this.calcDays();
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    );
  }

  goToRetry(): void {
    this.router.navigate(['reservation/retry/' + this.apiKey + '/' + this.hashCotizacion])
  }

  calcDays() {
    this.dateExp =  new Date();
    this.dateExp.setDate(this.dateExp.getDate() + this.daysToExp);
  }

  goToMail(): void {
    this.router.navigate(['reservation/receipt/mail/'+this.apiKey+'/'+this.hashCotizacion])
  }  
}
