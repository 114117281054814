import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'number_format'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, country: string): string {
    let formatter = null;
    switch (country) {
      default:
      case 'es-CL':
        formatter = new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP',
        });
        break;
      case 'es-RaulDelRio':
        formatter = new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP',
        });
        break;
      case 'es-PE':
        formatter = new Intl.NumberFormat('es-PE', {
          style: 'currency',
          currency: 'PEN',
        });
        break;
      case 'es-PE-DL':
        formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        break;
    }

    return formatter.format(value);
  }
}