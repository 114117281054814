<app-receipt *ngIf="reservaPagada"></app-receipt>
<app-retry-payment *ngIf="pagoFallido"></app-retry-payment>
<div class="container-fluid" id="conditions" *ngIf="!pagoFallido && !reservaPagada && !isLoading">
  <app-header-steps></app-header-steps>
  <div class="row">
    <div class="col-12 px-0 px-md-2">
      <div class="align-content-center justify-content-center d-flex">
        <app-timer style="position: absolute;top: 5.5rem;"></app-timer>
      </div>
      <mat-horizontal-stepper class="disable" linear labelPosition="bottom" #stepper>
        <mat-step color="basic" [stepControl]="firstFormGroup" label="Step 1" editable=true >
          <div class="text-center">
            <div class="spinner-border" role="status" *ngIf="isLoading">
              <span class="sr-only">Cargando...</span>
            </div>
          </div>
          <div id="firstStep" *ngIf="!isLoading">
            <div (submit)="submit()" [formGroup]="firstFormGroup" autocomplete="off">
              <ng-template matStepLabel >Datos</ng-template>
              <div class="container-fluid content p-4" >
                <div class="row" >
                  <div class="row px-3" id="data-confirmation">
                    <div class="col-12">
                      <h3 class="txt-subtitle">Confirma y completa tus datos para emitir el comprobante de pago.</h3>
                      <p class="p-1" >
                        Todos los datos solicitados son obligatorios.
                      </p>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            account_circle
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2 ">
                            <mat-label>Nombres</mat-label>
                            <input matInput placeholder="Ej. Maria" required formControlName="clientFirstName" type="text">
                            <ng-container *ngIf="submitted && f.clientFirstName.invalid">
                              <mat-error *ngIf="f.clientFirstName.errors?.required">Campo requerido</mat-error>
                              <mat-error *ngIf="f.clientFirstName.errors?.pattern">Solo puede ingresar letras</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            account_circle
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Apellido Paterno</mat-label>
                            <input matInput placeholder="Ej. Romero" required formControlName="clientFatherName" >
                            <ng-container *ngIf="submitted && f.clientFatherName.invalid">
                              <mat-error *ngIf="f.clientFatherName.errors?.required">Campo requerido</mat-error>
                              <mat-error *ngIf="f.clientFatherName.errors?.pattern">Solo puede ingresar letras</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            account_circle
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Apellido Materno</mat-label>
                            <input matInput placeholder="Ej. Martinez" required formControlName="clientMotherName">
                            <ng-container *ngIf="submitted && f.clientMotherName.invalid">
                              <mat-error *ngIf="f.clientMotherName.errors?.required">Campo requerido</mat-error>
                              <mat-error *ngIf="f.clientMotherName.errors?.pattern">Solo puede ingresar letras</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            email
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Correo</mat-label>
                            <input matInput placeholder="Ej. pat@ejemplo.com" required formControlName="clientMail" >
                            <ng-container *ngIf="submitted && f.clientMail.invalid">
                              <mat-error *ngIf="f.clientMail.errors?.required">Campo requerido</mat-error>
                              <mat-error *ngIf="f.clientMail.errors?.pattern">Correo no válido</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            stay_primary_portrait
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Celular</mat-label>
                            <input matInput placeholder="+56912345678" type="text" required formControlName="clientMobileNumber">
                            <ng-container *ngIf="submitted && f.clientMobileNumber.invalid">
                              <mat-error *ngIf="f.clientMobileNumber.errors?.required">Campo requerido</mat-error>
                              <mat-error *ngIf="f.clientMobileNumber.errors?.pattern">Solo puede ingresar numeros</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            event
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2" >
                            <mat-label>Fecha de Nacimiento</mat-label>
                            <input matInput placeholder="DD/MM/YYYY" required [matDatepicker]="picker" [max]="maxDate" [min]="minDate" required formControlName="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <ng-container *ngIf="submitted && f.picker.invalid">
                              <mat-error *ngIf="f.picker.errors?.required">Campo requerido</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            emoji_transportation
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Dirección</mat-label>
                            <input matInput placeholder="Ej. Hernando de Aguirre" type="text" required formControlName="clientAddress" >
                            <ng-container *ngIf="submitted && f.clientAddress.invalid">
                              <mat-error *ngIf="f.clientAddress.errors?.required">Campo requerido</mat-error>
                              <mat-error *ngIf="f.clientAddress.errors?.pattern">Solo puede ingresar letras</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-2 col-xs-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            person_pin_circle
                            </span>
                        </div>
                        <div class="col-10 col-xs-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Número</mat-label>
                            <input matInput placeholder="Ej. 456" type="number" required formControlName="clientAddressNumber" >
                            <ng-container *ngIf="submitted && f.clientAddressNumber.invalid">
                              <mat-error *ngIf="f.clientAddressNumber.errors?.required">Campo requerido</mat-error>
                              <mat-error *ngIf="f.clientAddressNumber.errors?.pattern">Solo puede ingresar numeros</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-2 col-xs-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            home
                            </span>
                        </div>
                        <div class="col-10 col-xs-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Depto / casa</mat-label>
                            <input matInput placeholder="Ej. Dpto 234" type="text" formControlName="clientComplement" >
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="showChile">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            location_on
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Región</mat-label>
                            <mat-select required formControlName="clientCity"  (selectionChange)="onSelectedRegion($event.value)" >
                              <mat-option *ngFor="let city of regionsData" [value]="city.idRegion">
                                {{city.glosaRegion}}
                              </mat-option>
                            </mat-select>
                            <ng-container *ngIf="submitted && f.clientCity.invalid">
                              <mat-error *ngIf="f.clientCity.errors?.required">Seleccione región</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="showChile">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            gps_fixed
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Comuna</mat-label>
                            <mat-select required formControlName="clientNeighborhood" >
                              <mat-option *ngFor="let neighborhood of comunas" [value]="neighborhood.idComuna">
                                {{neighborhood.glosaComuna}}
                              </mat-option>
                            </mat-select>
                            <ng-container *ngIf="submitted && f.clientNeighborhood.invalid">
                              <mat-error *ngIf="f.clientNeighborhood.errors?.required">Seleccione comuna</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="showPeru">
                      <div class="row">
                        <div class="col-2 col-sm-1 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            place
                            </span>
                        </div>
                        <div class="col-10 col-sm-11">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Departamento</mat-label>
                            <mat-select required formControlName="clientStatePeru" (selectionChange)="onSelectedDepartamento($event.value)" >
                              <mat-option *ngFor="let departamento of departamentoData" [value]="departamento.idDepartamento">
                                {{departamento.glosaDepartamento}}
                              </mat-option>
                            </mat-select>
                            <ng-container *ngIf="submitted && f.clientStatePeru.invalid">
                              <mat-error *ngIf="f.clientStatePeru.errors?.required">Seleccione departamento</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3" *ngIf="showPeru">
                      <div class="row">
                        <div class="col-2 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            pin_drop
                            </span>
                        </div>
                        <div class="col-10">
                          <mat-form-field class="w-100 pl-md-2">
                            <mat-label>Província</mat-label>
                            <mat-select required formControlName="clientCityPeru" (selectionChange)="onSelectedProvincia($event.value)">
                              <mat-option *ngFor="let provincia of provincias" [value]="provincia.idProvincia">
                                {{provincia.glosaProvincia}}
                              </mat-option>
                            </mat-select>
                            <ng-container *ngIf="submitted && f.clientCityPeru.invalid">
                              <mat-error *ngIf="f.clientCityPeru.errors?.required">Seleccione província</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3" *ngIf="showPeru">
                      <div class="row">
                        <div class="col-2 d-flex align-items-center">
                          <span class="material-icons pb-4">
                            gps_fixed
                            </span>
                        </div>
                        <div class="col-10 ">
                          <mat-form-field class="w-100 px-md-2">
                            <mat-label>Distrito</mat-label>
                            <mat-select required formControlName="clientNeighborhoodPeru" >
                              <mat-option *ngFor="let distrito of distritos" [value]="distrito.idDistrito" >
                                {{distrito.glosaDistrito}}
                              </mat-option>
                            </mat-select>
                            <ng-container *ngIf="submitted && f.clientNeighborhoodPeru.invalid">
                              <mat-error *ngIf="f.clientNeighborhoodPeru.errors?.required">Seleccione distrito</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                </div>
                <div class="row justify-content-center align-items-center pt-4">
                  <div class="col-md-6 d-flex align-items-center justify-content-center">
                    <button class="btn btn-dark btn-lg mr-2 btn-submit w-100" (click)="submit()" *ngIf="showChile" matStepperNext>Continuar</button>
                    <button class="btn btn-dark btn-lg mr-2 btn-submit w-100" (click)="submitPeru()" *ngIf="showPeru"  matStepperNext>Continuar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" editable=true label="Step 2" >
          <div class="spinner-border" role="status" *ngIf="isLoading">
            <span class="sr-only">Cargando...</span>
          </div>
          <div id="secondStep" *ngIf="!isLoading">
            <form >
              <ng-template matStepLabel>Unidad</ng-template>
              <div class="container-fluid content p-4" >
                <div class="row">
                  <div class="row p-3 content-display-size scrollbar" id="unit-confirmation">
                    <div class="col-md-12 w-100">
                      <div class="row">
                        <div class="col-2 col-sm-1 pr-0 d-flex align-items-start">
                        <span class="material-icons pb-2">
                          home
                          </span>
                        </div>
                        <div class="col-10 col-sm-11 pl-0">
                          <h3 class="txt-subtitle mb-0" i18n="Título unidades| titulo del paso unidades@@tituloUnidades" >{{multilingualContent.reservation.tituloUnidades}}</h3>
                        </div>
                      </div>
                      <p >
                        Confirma si esta unidad es la que deseas.
                      </p>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="table-responsive">
                            <table class="table table-borderless ">
                              <tbody>
                              <tr>
                                <th scope="row" width="40%" class="pb-0" *ngIf="showChile"><img class="logo align-content-center w-100" src="{{quotationData.proyecto.imagenProyecto}}" alt="logo proyecto"></th>
                                <th scope="row" width="40%" class="pb-0" *ngIf="showPeru"><img class="logo align-content-center w-100" src="{{quotationDataPeru.proyecto.imagenProyecto}}" alt="logo proyecto"></th>
                                <td class="txt-size-14 pb-0" width="60%"> <p class="txt-size-16 mb-2 font-weight-bold">Proyecto {{quotationDataPeru.proyecto.glosaProyecto}}</p>   <span class="material-icons pb-4 txt-size-14">
                            location_on</span> {{ quotationDataPeru.proyecto.direccion}}</td>
                              </tr>
                              <tr class="txt-size-14">
                                <th scope="row" class="pb-0">Tipo de propiedad</th>
                                <td class="pb-0" >{{quotationData.producto.tipoProducto | lowercase}}</td>
                              </tr>
                              <tr class="txt-size-14">
                                <th scope="row" class="pb-0">Modelo</th>
                                <td class="pb-0">{{ quotationData.modelo.cantDormitorios }}D {{ quotationData.modelo.cantBanos }}B</td>
                              </tr>
                              <tr class="txt-size-14">
                                <th scope="row" class="pb-0">Área total</th>
                                <td class="pb-0">{{ quotationData.producto.supTotal }} m2</td>
                              </tr>
                              <tr class="txt-size-14">
                                <th scope="row" class="pb-0">Subagrupación</th>
                                <td class="pb-0">{{quotationData.subagrupacion.glosaSubagrupacion}}</td>
                              </tr>
                              <tr class="txt-size-14">
                                <th scope="row" class="pb-0">Unidad</th>
                                <td class="pb-0">{{quotationData.producto.glosaProducto}}</td>
                              </tr>
                              <tr class="txt-size-14">
                                <th scope="row" class="pb-0">Orientación</th>
                                <td class="pb-0">{{ quotationData.producto.orientacion }}</td>
                              </tr> 
                              <tr *ngFor="let productoSecundario of quotationData.productoSecundario" class="txt-size-14">
                                <th scope="row" class="pb-0">{{ productoSecundario["tipo"] }}</th>
                                <td class="pb-0">{{ productoSecundario["glosa"] }}</td>
                              </tr>     
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-6 justify-content-center">
                          <div class="row">
                            <div class="col-md-12 col-xs-12  col-sm-12 plan d-flex justify-content-center" *ngIf="quotationData.modelo.imagenModelo.length > 0 && showChile">
                              <img class="img-plantilla" style="max-height: 100%;" src="{{quotationData.modelo.imagenModelo}}" alt="plantilla unidad">
                            </div>
                            <div class="col-md-12 col-xs-12  col-sm-12 plan d-flex justify-content-center" *ngIf="quotationData.modelo.imagenModelo.length > 0 && showPeru">
                              <img class="img-plantilla" style="max-height: 100%;" src="{{quotationDataPeru.modelo.imagenModelo}}" alt="plantilla unidad">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center align-items-baseline pt-4 pt-sm-0">
                  <div class="col-md-6">
                    <button class="btn btn-outline-dark btn-lg mt-4 mr-2 btn-cancel w-100" matStepperPrevious>Volver</button>
                  </div>
                  <div class="col-md-6">
                    <button class="btn btn-dark btn-lg mt-4 mr-2 btn-submit w-100" matStepperNext>Continuar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup" label="Step 3" >
          <div class="spinner-border" role="status" *ngIf="isLoading">
            <span class="sr-only">Cargando...</span>
          </div>
          <div *ngIf="!isLoading">
            <form [formGroup]="thirdFormGroup" ngNativeValidate>
              <ng-template matStepLabel>Método de Pago</ng-template>
              <div class="container-fluid content p-4" >

                <div class="col-md-12 w-100 p-2" style="background-color: #E4E3E3; border-radius: 20px;">
                  <div class="table-responsive">
                    <table class="table table-borderless">
                      <thead>
                      <tr>
                        <td class="pb-0" scope="col"> <h4 class="txt-subtitle mb-0 pb-0"> <span class="material-icons pr-4">
                        monetization_on
                          </span>{{multilingualContent.reservation.etiquetaValorPago}}</h4>
                        </td>
                        <td class="text-right pb-0" scope="col"><h4><b>{{ previousReservation.valorReserva | number_format: quotationData.lenguaje }}</b> {{multilingualContent.reservation.monedaValorPago}}</h4></td>
                      </tr>
                      </thead>
                      <tbody class="txt-size-14">
                      <tr>
                        <td class="pb-0">{{quotationData.proyecto.glosaProyecto}} </td>
                        <td class="text-right pb-0">{{quotationData.cotizacion.glosaProducto}}</td>
                      </tr>
                      <tr>
                        <td class="pb-0">Fecha de emisión</td>
                        <td class="text-right pb-0">{{dateHoy | date_format: quotationData.lenguaje}}</td>
                      </tr>
                      <tr>
                        <td class="pb-0" >{{multilingualContent.reservation.etiquetaFechaCaducidad}}</td>
                        <td class="text-right pb-0">{{dateExp | date_format: quotationData.lenguaje}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mx-2" id="metodoTransbank" *ngIf="showChile">
                    <div class="row my-2">
                      <div class="col-3 col-sm-2 pr-0 py-0 m-0 d-flex align-items-center">
                        <span >
                <img class="w-75" style="min-height: 100%;" src="../../../../assets/img/logowebpay_1.png" alt="logo Transbank Chile">
                </span>
                      </div>
                      <div class="col-9 col-sm-10 m-0">
                        <p class="txt-subtitle-2 mb-0 mt-4">Transbank Webpay</p>
                        <p class="txt-size-14 mb-0 mt-2" >Permite el pago de productos y/o servicios, con Tarjetas de Crédito y Redcompra a través de Webpay Plus</p>
                      </div>
                    </div>
                  </div>
                   <div class="col-md-12 mx-2" id="metodoVisanet" *ngIf="showPeru">
                    <div class="row my-2">
                      <div class="col-3 col-sm-2 pr-0 py-0 m-0 d-flex align-items-center">
                        <span >
                <img class="w-75" style="min-height: 100%;" src="../../../../assets/img/logo_niubiz.PNG" alt="logo niubiz Perú" >
                </span>
                      </div>
                      <div class="col-9 col-sm-10 m-0">
                        <p class="txt-subtitle-2 mb-0 mt-4">Niubiz Visanet</p>
                        <p class="txt-size-14 mb-0 mt-2" >Permite el pago de productos y/o servicios, con Tarjetas de Crédito o Débito, código QR de tu billetera eletrónica, transferencias bancarias y depósitos en efectivo.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 txt-size-14">
                    <p class="mt-4">
                      <span id="id-client" ></span>Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web y otros propósitos descritos en nuestra
                      <a class="link" href="https://www.planok.com/tyc/" role="button" target="_blank" >política de privacidad</a> y los <a class="link" data-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2" alt="link terminos">términos y condiciones*</a>
                    </p>
                    <div class="row m-2">
                      <div class="w-100">
                        <div class="collapse multi-collapse" id="multiCollapseExample2" >
                          <div class="card card-body w-100 text-left mt-2" rows="4" style="background-color:#E4E3E3; border-radius: 10px; border: none;" [innerHTML]="quotationData.etapa.terminosCondiciones">
                          </div>
                        </div>
                      </div>
                    </div>
                    <mat-slide-toggle color="primary" formControlName="acceptTerms" required (change)="enableButton()"><span id="id-conditions" class="txt-subtitle"></span>Estoy de acuerdo con los términos y condiciones*
                    </mat-slide-toggle>
                  
                  </div>
                </div>
                <div class="row justify-content-center align-items-baseline pt-4 pt-sm-0">
                  <div class="col-md-6">
                    <button class="btn btn-outline-dark btn-lg mt-4 mr-2 btn-cancel w-100" matStepperPrevious>Volver</button>
                  </div>
                  <div class="col-md-6" id="pagoTransbank" *ngIf="showChile">
                    <button class="btn btn-dark btn-lg mt-4 mr-2 btn-submit w-100" type="button" (click)="getWebpayToken()" [disabled]="disabledPayButton === true">
                      Ir a pagar
                      <div class="spinner-border spinner-button" role="status" *ngIf="webpayLoading">
                        <span class="sr-only">Cargando...</span>
                      </div>
                    </button>
                    <form  ngNoForm method="post" [action]="webpayUrl" id="formWebpay">
                      <input id="token_ws" type="hidden" name="token_ws" [value]="webpayToken" >
                      <input type="submit" hidden id="submitWebpayButton">
                    </form>
                  </div>
                    <div class="col-md-6" id="pagoVisanet" *ngIf="showPeru">
                      <div id="botonPagoVisanet"></div>
                        <button class="btn btn-dark btn-lg mt-4 mr-2 btn-submit w-100" type="button" (click)="formularioPagoVisanet()" [disabled]="disabledPayButton === true">
                          Pagar
                          <div class="spinner-border spinner-button" role="status" *ngIf="webpayLoading">
                            <span class="sr-only">Cargando...</span>
                          </div>
                        </button>
                    </div>
                </div>
              </div>
            </form>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Comprobante</ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>