import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ProductUpdateService } from '../../services/productUpdate/product-update.service';
import { ProductUnblockService } from '../../services/productUnblock/product-unblock.service';

interface Time{
  minutes: number,
  seconds: number
}

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

duration: number;
timeLeft: number;
interval;
minutes;
seconds;

apiKey: string;
hashCotizacion: string;
message: string;
action: string;

  constructor(private router: Router,
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private productUpdateService: ProductUpdateService,
    private productUnblockService: ProductUnblockService) {
    this.apiKey = this._route.snapshot.paramMap.get('apikey');
    this.hashCotizacion = this._route.snapshot.paramMap.get('hash');
     }

  ngOnInit() {
    this.startTimer(10);
    
  }

  startTimer(minutes){
    this.duration = minutes;
    this.timeLeft = this.duration * 60;
    this.interval = setInterval(() => {
      this.minutes = Math.floor(this.timeLeft / 60);
      this.seconds = this.timeLeft  % 60;
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds
      if(this.timeLeft > 0) {
        this.timeLeft--;
        
      } else {
        
        this.productUpdateService.updateProduct(this.apiKey, this.hashCotizacion).subscribe(
          resp => {
            console.log("update product", resp);
            this.openSnackBarTimer()
            this.reStart();
          },
          (error) => {
            console.log("error", error);
          }
        )
        console.log('Añadimos más 5 minutos');
      }
    },1000)
  }

  reStart(){
    clearInterval(this.interval);

    this.duration = 5;
    this.timeLeft = this.duration * 60;
    this.interval = setInterval(() => {
      this.minutes = Math.floor(this.timeLeft / 60);
      this.seconds = this.timeLeft  % 60;
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds
      if(this.timeLeft > 0) {
        this.timeLeft--;
      }
      if(this.timeLeft === 0) {
        clearInterval(this.interval);
        this.productUnblockService.unblockProduct(this.apiKey, this.hashCotizacion).subscribe(
          resp => {
            console.log("Producto desbloqueado", resp);
            this.backToWelcome();
            this.openSnackBarWelcome();
          },
          (error) => {
            console.log("error", error);
          }
        )
       
      }
    },1000)
  }

  // funccion para rutar para la vista welcome
  backToWelcome(): void {
    this.router.navigate(['welcome/' + this.apiKey + '/' + this.hashCotizacion])
  }

  openSnackBarTimer() {
    this.message = "Añadimos 5 minutos más, para que tu finalices el proceso.";
    this.action = "Cerrar"
    this._snackBar.open(this.message, this.action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  openSnackBarWelcome() {
    this.message = "Se agotó el tiempo, pero aún se puede reservar.";
    this.action = "Cerrar"
    this._snackBar.open(this.message, this.action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

}
