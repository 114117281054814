import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuotationDataModel } from '../models/quotation-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class QuotationDataService {
  url: string;
  body: any;
  apyKey: string;

  constructor(protected http: HttpClient ){
    
  }

  getQuotationData(apikey: string, hash: string): Observable<QuotationDataModel> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
      
    return this.http.get<QuotationDataModel>(environment.urlApi + '/cotizaciones/'+ hash + '/datos-cotizacion',{headers});
  }
}
