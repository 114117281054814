import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { QuotationDataService } from '../../services/quotation-data.service';
import { QuotationDataModel } from '../../models/quotation-data.model';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PreviousReservationModel } from '../../models/previousReservation.model';
import { PreviousReservationDataService } from '../../services/previousReservationData/previous-reservation-data.service';
import { ProductoModel } from '../../models/producto.model';
import { CaptchaService } from '../../services/captcha/captcha.service';
import { environment } from 'src/environments/environment';
import { RxTranslation, translate } from "@rxweb/translate";



@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit {
  @translate() multilingualContent:{[key:string]:any};
  isLoading: boolean;
  isChecked = false;
  quotationData: QuotationDataModel;
  previousReservation: PreviousReservationModel;
  idConfirmed: boolean;
  welcomeFormGroup: FormGroup;
  lockedProducto = false;
  validityOfQuotation = false;
  apiKey: string;
  hashCotizacion: string;
  captcha: string;
  message: string;
  action: string;
  enviroment: any;
  leng: string;
  showChile: boolean = false;
  showPeru: boolean = false;
  productoSecundario: any;

  constructor(
    private router: Router,
    private quotationDataService: QuotationDataService,
    private reservationService: PreviousReservationDataService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private captchaService: CaptchaService,
    private traslation: RxTranslation,
  ) {
    this.apiKey = this._route.snapshot.paramMap.get('apikey');
    this.hashCotizacion = this._route.snapshot.paramMap.get('hash');
    this.welcomeFormGroup = this.formBuilder.group({
      idConfirmed: [false, Validators.requiredTrue]
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.changeCountry();
    this.quotationDataService.getQuotationData(this.apiKey, this.hashCotizacion).subscribe(
      response => {
        this.quotationData = response;
        this.traslation.change(this.quotationData.lenguaje);
        
        if (Object.entries(this.quotationData.productoSecundario).length === 0) {
          this.productoSecundario = "vacio";
          
        } else {
          this.productoSecundario = this.quotationData.productoSecundario
        }

        this.checkDiponibilidadProducto(this.quotationData.producto, this.productoSecundario);
      },
      (error) => {
        this.validityOfQuotation = true;
        this.isLoading = false;
        console.log('Error al momento de Cargar los datos de Cotización', error);
      }
    );
  }

  changeCountry(){
    this.leng = environment.leng;
    switch(this.leng){
      case 'es-CL': 
      this.showChile = true;
      break;
      case 'es-PE': 
      case 'es-PE-DL': 
      this.showPeru = true;
      break;
    }
  }

  getReservationData() {
    this.reservationService.getReservations(this.apiKey, this.hashCotizacion).subscribe(
      response => {
        this.previousReservation = response;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    );
  }

  checkDiponibilidadProducto(producto: ProductoModel, productoSecundario: any) {
    if (producto.estadoProducto !== 'Disponible' || producto.activoWeb !== 0 || producto.preReservado !== 0 || producto.activoSistema !== 0) {
      this.lockedProducto = true;
      this.isLoading = false;
    } else {
      if (productoSecundario != "vacio") {
        productoSecundario.forEach(ps => {
          if (ps.estado !== 'Disponible' || ps.activo_web !== 0 || ps.pre_reserva !== 0 || ps.activo !== 0) {
            this.lockedProducto = true;
            this.isLoading = false;
          } else{
            this.getReservationData();
          }
        })
      } else {
        this.getReservationData();
      }
    }
  }

  resolved(captchaResponse: string) {
    this.captchaService.sendToken(this.apiKey,captchaResponse).subscribe(
      data => {
        if (data.google_response.success) {
          this.captcha = "on";
        } else {
          this.captcha = "off";
        }
      },
      err => {
        console.log(err);
        this.captcha = "off";
      },
      () => {}
    );
    
  }
  
  submit(): void {
    if (this.captcha == "on") {
      if (!this.welcomeFormGroup.controls.idConfirmed.value) {
        console.log(this.welcomeFormGroup.controls.idConfirmed.value);
      } else {
        console.log(this.welcomeFormGroup.controls.idConfirmed.value);
        this.startReservation();
      }  
    } else {
      this.openSnackBarCaptcha();      
    }
    
  }

  openSnackBarCaptcha() {
    this.message = "Debe confirmar el Captcha";
    this.action = "Cerrar"
    this._snackBar.open(this.message, this.action, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  startReservation(): void {
      this.router.navigate(['/reservation/' + this.apiKey + '/' + this.hashCotizacion]);
  }

  quitReservation(): void {
    window.location.href=`${this.quotationData.proyecto.urlProyecto}`;
  }
}
