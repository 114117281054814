import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductUpdateService {

  constructor(protected http: HttpClient) { }

  updateProduct(apikey: string, hash: string): Observable<any> {
    console.log(`Producto ${hash} actualizado`);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);
    let body = '{ \"minutos\": 5}'
    return this.http.put<any>(environment.urlApi + '/producto/' + hash + '/actualizar-bloqueo', body ,{headers});    
  }

}

