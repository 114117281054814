import {NgModule} from "@angular/core";
import {TipoPagoPipe} from "./tipoPago.pipe";
import {TipoCuotaPipe} from "./tipoCuota.pipe";
import {NumberFormatPipe} from "./number-format.pipe";
import { DateFormatPipe } from "./date-format.pipe";

@NgModule({
  imports: [],
  declarations: [
    TipoPagoPipe,
    TipoCuotaPipe,
    NumberFormatPipe,
    DateFormatPipe
  ],
  exports: [
    TipoPagoPipe,
    TipoCuotaPipe,
    NumberFormatPipe,
    DateFormatPipe
  ]
})
export class PipesModule {

}
