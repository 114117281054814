<div class="container-fluid" id="conditions">
  <app-header-steps *ngIf="!isLoading"></app-header-steps>
  <div class="row">
    <div class="col-12 px-0 px-md-4">
      <div class="spinner-border" role="status" *ngIf="isLoading">
        <span class="sr-only">Cargando...</span>
      </div>
      <div *ngIf="!isLoading">
        <div class="container-fluid content p-4" >
          <div class="col-md-12 p-3 align-content-baseline d-flex" style="background-color: rgb(170, 178, 228); border-radius: 20px;">
            <span class="material-icons md-36 pr-2">
             email
             </span><span class="txt-size-14 mt-1">Una copia de este comprobante fue enviada a tu correo eletrónico</span>
          </div>        
          <div class="col-12 align-content-center text-center mt-4">
            <h2 class="txt-title p-3">¡El pago fue aprobado!</h2>
            <img src="../../../../assets/img/res-ok.svg" alt="logo-proyecto">
            <p class="txt-size-14">Fecha emisión<strong> {{maxDate | date_format: quotationData.lenguaje}} hrs</strong></p>
            <p class="txt-size-14">{{multilingualContent.receipt.etiquetaFechaCaducidadComprobante}}<strong> {{dateExp | date_format: quotationData.lenguaje}} hrs</strong></p>
            <p class="txt-size-18"><b> {{ previousReservation.valorReserva | number_format: quotationData.lenguaje }}</b> {{multilingualContent.receipt.etiquetaMonedaComprobanteUno}} </p>
          </div>

          <div class="col-md-12 mx-2">
            <div class="row my-2">
              <div class="col-12 m-0">
                <h4 class="txt-title text-center pb-2 pt-4" >{{multilingualContent.receipt.resumen}} #{{previousReservation.idReserva}}</h4>
              </div>
              <div class="col-12 col-sm-12 m-0 text-left">
                <p class="txt-size-14 mt-2 mb-2">Hola <b>{{quotationData.cliente.natNombre}} {{quotationData.cliente.natApellidoPaterno}}</b>,
                </p>
                <p class="txt-size-14 mt-2 mb-2">
                  {{multilingualContent.receipt.descripcionEmailComprobante}}<br><br>
                </p>
              </div>
              
            </div>
          </div>
          <div class="col-12 my-2 p-2" style="background-color: #E4E3E3; border-radius: 20px;" *ngIf="showChile">
            <div class="row px-3">
            <div class="col-6 col-sm-9 pt-2 pl-0 align-content-end d-flex">
                      <span class="material-icons md-36 pr-2">
                        monetization_on
                       </span><span class="txt-subtitle mb-0 pb-0">Datos de la transacción</span>
            </div>
            <div class="col-6 col-sm-3 pr-0 pl-0 py-0 m-0 d-flex justify-content-end align-items-center" id="logoTransbankChile">
                      <img class="w-50" src="../../../../assets/img/logowebpay.png" alt="logo Transbank">
              </div>
            </div>
            <div class="pt-2" style="border-bottom: 1px solid #555;"></div>
            <div class="row px-3">
            <div class="col-xs-12 col-sm-6 ">
            <div class="table-responsive">
              <table class="table table-borderless txt-size-12">
                <thead>
                  <tr>
                    <td class="pb-0 font-weight-bold" width="60%" scope="col">#Orden de compra</td>
                    <td class=" pb-0" scope="col" width="40%">{{ quotationData.deuda.resultadoTransaccion.detail.buy_order}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Fecha de transacción</td>
                    <td class="pb-0">{{maxDate | date_format: quotationData.lenguaje}} hrs</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold" scope="col">Concepto</td>
                    <td class="pb-0" scope="col">{{quotationData.deuda.detalleDeuda}}</td>
                  </tr>
                <tr>
                  <td class="pb-0 font-weight-bold">Tipo de pago</td>
                  <td class="pb-0">{{quotationData.deuda.resultadoTransaccion.detail.payment_type_code | tipoPago}}</td>
                </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 ">
            <div class="table-responsive">
              <table class="table table-borderless txt-size-12">
                <tbody>
                  <tr >
                    <td class="pb-0 font-weight-bold" scope="col" width="60%">Monto del pago</td>
                    <td class="pb-0" scope="col" width="40%" ><b>{{ previousReservation.valorReserva | number_format: quotationData.lenguaje}}</b></td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Tarjeta terminada en</td>
                    <td class=" pb-0"> xxxx-xxxx-xxxx-{{quotationData.deuda.resultadoTransaccion.card_number}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Código de autorización</td>
                    <td class=" pb-0">{{quotationData.deuda.resultadoTransaccion.detail.authorization_code}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Tipo de Cuota</td>
                    <td class=" pb-0">{{quotationData.deuda.resultadoTransaccion.detail.payment_type_code | tipoCuota}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold" scope="col">Estado</td>
                    <td class=" pb-0 " scope="col">{{quotationData.deuda.estado | uppercase}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
          </div>
           <div class="col-md-12 w-100 my-2 p-2" style="background-color: #E4E3E3; border-radius: 20px;" *ngIf="showPeru">
            <div class="row px-3">
            <div class="col-6 col-sm-9 pt-2 pl-0 align-content-end d-flex">
                      <span class="material-icons md-36 pr-2">
                        monetization_on
                       </span><span class="txt-subtitle mb-0 pb-0">Datos de la transacción</span>
            </div>
            <div class="col-6 col-sm-3 pr-0 pl-0 py-0 m-0 d-flex justify-content-end align-items-center" id="logoVisanetPeru">
              <img class="w-50" src="../../../../assets/img/logo_niubiz.PNG" alt="logo Niubiz">
            </div>
            </div>
            <div class="pt-2" style="border-bottom: 1px solid #555;"></div>
            <div class="row px-3">
            <div class="col-xs-12 col-sm-6 ">
            <div class="table-responsive">
              <table class="table table-borderless txt-size-12">
                <thead>
                  <tr>
                    <td class="pb-0 font-weight-bold" scope="col" width="50%">#Orden de compra</td>
                    <td class=" pb-0" scope="col" width="50%">{{ quotationDataPeru.cobro_visanet.resultadoTransaccion.dataMap.TRANSACTION_ID}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Fecha de transacción</td>
                    <td class="pb-0">{{maxDate | date_format: quotationData.lenguaje}} hrs</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Tipo de tarjeta</td>
                    <td class="pb-0">{{quotationDataPeru.cobro_visanet.resultadoTransaccion.dataMap.BRAND}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold" scope="col">Concepto</td>
                    <td class="pb-0" scope="col">{{quotationDataPeru.cobro_visanet.detalleCobro}}</td>
                  </tr>
               
                </thead>
              </table>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 ">
            <div class="table-responsive">
              <table class="table table-borderless txt-size-12">
                <tbody>
                  <tr >
                    <td class="pb-0 font-weight-bold" scope="col" width="50%">Monto del pago</td>
                    <td class="pb-0" scope="col" width="40%"><b> {{ quotationDataPeru.cobro_visanet.resultadoTransaccion.order.amount | number_format: quotationData.lenguaje}}</b> {{ quotationDataPeru.cobro_visanet.resultadoTransaccion.order.currency}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Tarjeta terminada en</td>
                    <td class=" pb-0">{{quotationDataPeru.cobro_visanet.resultadoTransaccion.dataMap.CARD}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold">Código de autorización</td>
                    <td class=" pb-0">{{quotationDataPeru.cobro_visanet.resultadoTransaccion.dataMap.AUTHORIZATION_CODE}}</td>
                  </tr>
                  <tr>
                    <td class="pb-0 font-weight-bold" scope="col">Estado</td>
                    <td class=" pb-0 " scope="col">{{quotationDataPeru.cobro_visanet.estado}}</td>
                  </tr>
                
                </tbody>
              </table>
            </div>
          </div>
          </div>
          </div>

          <div class="col-md-12 w-100 p-2 my-2">
            <div class="col-md-12 pt-2 pl-0 align-content-baseline d-flex">
                    <span class="material-icons md-36 pr-2">
                      account_circle
                     </span><span class="txt-subtitle mb-0 pb-0">Datos del Comprador</span>
            </div>
            <div class="pt-2" style="border-bottom: 1px solid #555;"></div>
            <div class="row px-3">
            <div class="col-xs-12 col-sm-6 ">
            <div class="table-responsive">
              <table class="table table-borderless text-left txt-size-12" >
                <thead>
                <tr>
                  <td class="pb-0 font-weight-bold" scope="col">Señor(a)</td>
                  <td class="pb-0" scope="col" *ngIf="(showChile)||(showPeru)">{{quotationData.cliente.natNombre}} {{quotationData.cliente.natApellidoPaterno}}</td>
                </tr>
                <tr>
                  <td class="pb-0 font-weight-bold" scope="col" >{{multilingualContent.receipt.idClienteComprobante}}</td>
                  <td class="pb-0" scope="col" *ngIf="showChile">{{quotationData.cliente.natRut}}-{{quotationData.cliente.natDvRut}}</td>
                  <td class="pb-0" scope="col" *ngIf="showPeru">{{quotationDataPeru.cliente.natRut}}</td>
                </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 ">
            <div class="table-responsive">
              <table class="table table-borderless text-left txt-size-12" >
                <thead *ngIf="(showChile)||(showPeru)">
                <tr>
                  <td class="pb-0 font-weight-bold" scope="col">Teléfono</td>
                  <td class="pb-0" scope="col">{{quotationData.cliente.natFonoCelular}}</td>
                </tr>
                <tr>
                  <td class="pb-0 font-weight-bold" scope="col">E-mail</td>
                  <td class="pb-0" scope="col">{{quotationData.cliente.natEmail}}</td>
                </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
          
          <div class="col-md-12 w-100 p-0 my-2">
            <div class="col-md-12 pt-2 pl-0 align-content-baseline d-flex">
                  <span class="material-icons md-36 pr-2">
                    home
                   </span><span class="txt-subtitle mb-0 pb-0">Datos de la unidad</span>
            </div>
            <div class="pt-2" style="border-bottom: 1px solid #555;"></div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 col-sm-6 mt-2">
                  <div class="table-responsive">
                    <table class="table table-borderless " *ngIf="(showChile)||(showPeru)">
                      <tbody>
                      <tr>
                        <th scope="row" width="40%" class="pb-0" *ngIf="showChile"><img class="logo align-content-center w-100" src="{{quotationData.proyecto.imagenProyecto}}" alt="logo proyecto"></th>
                        <th scope="row" width="40%" class="pb-0" *ngIf="showPeru"><img class="logo align-content-center w-100" src="{{quotationDataPeru.proyecto.imagenProyecto}}" alt="logo proyecto"></th>
                        <td class="txt-size-12 pb-0" width="60%"> <p class="txt-size-16 mb-2 font-weight-bold">Proyecto {{quotationData.proyecto.glosaProyecto}}</p>   <span class="material-icons pb-4 txt-size-14">
                    location_on</span> {{ quotationData.proyecto.direccion}}</td>
                      </tr>
                      <tr class="txt-size-12">
                        <th scope="row" class="pb-0">Tipo de propiedad</th>
                        <td class="pb-0">{{quotationData.producto.tipoProducto | lowercase}}</td>
                      </tr>
                      <tr class="txt-size-12">
                        <th scope="row" class="pb-0">Modelo</th>
                        <td class="pb-0">{{ quotationData.modelo.cantDormitorios }}D {{ quotationData.modelo.cantBanos }}B</td>
                      </tr>
                      <tr class="txt-size-12">
                        <th scope="row" class="pb-0">Área total</th>
                        <td class="pb-0">{{ quotationData.producto.supTotal }} m2</td>
                      </tr>
                      <tr class="txt-size-12">
                        <th scope="row" class="pb-0">Subagrupación</th>
                        <td class="pb-0">{{quotationData.subagrupacion.glosaSubagrupacion}}</td>
                      </tr>
                      <tr class="txt-size-12">
                        <th scope="row" class="pb-0">Unidad</th>
                        <td class="pb-0">{{quotationData.producto.glosaProducto}}</td>
                      </tr>
                      <tr class="txt-size-12">
                        <th scope="row" class="pb-0">Orientación</th>
                        <td class="pb-0">{{ quotationData.producto.orientacion }}</td>
                      </tr>
                      <tr *ngFor="let productoSecundario of quotationData.productoSecundario" class="txt-size-14">
                        <th scope="row" class="pb-0">{{ productoSecundario["tipo"] }}</th>
                        <td class="pb-0">{{ productoSecundario["glosa"] }}</td>
                      </tr> 
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-6 justify-content-center mt-2">
                  <div class="row ">
                    <div class="col-12 col-sm-12 plan d-flex justify-content-center">
                     <img class="w-75" src="{{quotationData.modelo.imagenModelo}}" alt="plantilla unidad" *ngIf="showChile">
                     <img class="w-75" src="{{quotationDataPeru.modelo.imagenModelo}}" alt="plantilla unidad" *ngIf="showPeru">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 w-100 my-2 p-2" style="background-color: #E4E3E3; border-radius: 20px;" >
            <div class="row px-3">
              <div class="col-12 col-sm-9 pt-2 pl-0 align-content-end d-flex">
                <span class="material-icons md-36 pr-2">
                  monetization_on
                </span>
                <span class="txt-subtitle mb-0 pb-0">{{multilingualContent.receipt.terminosCondiciones}}</span>
              </div>
              <div class="pt-2" style="border-bottom: 1px solid #555;"></div>
            </div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 ">
                    <div class="table-responsive">
                      <table class="table table-borderless txt-size-12">
                        <thead>
                          <tr>
                            <td class="pb-0 font-weight-bold" width="100%" scope="col">
                              <p *ngIf="showPeru" [innerHTML] = "quotationDataPeru.etapa.terminosCondiciones"></p>
                              <p *ngIf="showChile" [innerHTML] = "quotationData.etapa.terminosCondiciones"></p>
                            </td>
                          </tr>
                        </thead>
                      </table>
                  </div>
                </div>
              </div>
          </div>
          <div class="text-center">
            <a
              id="link"
              type="button"
              alt="link sitio proyecto"
              href="{{quotationData.proyecto.urlProyecto}}"
              class="btn btn-dark btn-lg mt-4 mr-2 w-100" style="padding: 1rem !important;" *ngIf="showChile">
              Finalizar y volver al proyecto
            </a>
            <a
              type="button"
              alt="link sitio proyecto"
              href="{{quotationDataPeru.proyecto.urlProyecto}}"
              class="btn btn-dark btn-lg mt-4 mr-2 w-100" style="padding: 1rem !important;" *ngIf="showPeru">
              Finalizar y volver al proyecto
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
