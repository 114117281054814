import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QuotationDataPeruModel } from '../../models/quotation-data-peru.model';

@Injectable({
  providedIn: 'root'
})
export class QuotationDataPeruService {
  url: string;
  body: any;
  apyKey: string;

  constructor(protected http: HttpClient ){
    
  }
  getQuotationDataPeru(apikey: string, hash: string): Observable<QuotationDataPeruModel> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json'); 
      headers = headers.append('xApiKeyPublic', apikey);

    return this.http.get<QuotationDataPeruModel>(environment.urlApi + '/cotizaciones/'+ hash + '/datos-cotizacion-peru',{headers});
  }
}
